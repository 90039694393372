import {
  deleteJsonDocument,
  executeJob,
  fetchJsonDocument,
  postJsonDocuments,
  Scenario,
  updateJsonDocument,
} from '@dhi/react-components';
import { format } from 'date-fns';
import jp from 'jsonpath';
import { cloneDeep } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import {
  displayToField,
  getConfigSection,
  getFieldConfig,
  ListFieldValue,
  Validator,
} from 'src/components/SharedLibrary';
import {
  DesignDepthsProps,
  PortSustainabilityDTO,
  PortSustainabilityScenarioContainer,
} from 'src/modules/PortSustainability/__models/PortSustainabilityScenario';
import setDefaultValues from 'src/modules/PortSustainability/__utility/setDefaultValues';
import { ALL_CHANNELS } from 'src/modules/PortSustainability/constants';
import { v4 as uuidv4 } from 'uuid';
import { PSScenarioStore } from './PSScenarioStore';
import { RootStore } from './RootStore';

export class PortSustainabilityStore extends PSScenarioStore {
  initialScenarioDataState = {
    added: new Date(),
    dateTime: '',
    fullName: '',
    data: {},
    permissions: [],
  } as PortSustainabilityScenarioContainer;

  initialDesignDepth = {
    above: [],
    highTolerance: [],
    below: [],
  } as DesignDepthsProps;

  initialDataState = {
    name: '',
    debug: false,
    dredgeDate: new Date(),
    dredgingZones: [],
    results: [],
  } as PortSustainabilityDTO;

  dateTime = format(new Date(), 'yyyy-MM-dd');
  displayVolume = true;
  dredgingCost = 1;
  channelCategory = ALL_CHANNELS;
  selectedScenarios: Scenario[] = [];
  selectedZone = '';
  timeOfInterest = '';
  polygonZones = null;

  designDepth = this.initialDesignDepth;
  portSustainabilityData = this.initialDataState;

  constructor(allStores: RootStore) {
    super(allStores);
    this.scenarioData = this.initialScenarioDataState;

    makeObservable(this, {
      dateTime: observable,
      designDepth: observable,
      portSustainabilityData: observable,
      displayVolume: observable,
      dredgingCost: observable,
      channelCategory: observable,
      selectedScenarios: observable,
      selectedZone: observable,
      timeOfInterest: observable,
      polygonZones: observable,

      setDredgingCost: action.bound,
      setChannelCategory: action.bound,
      setDisplayVolume: action.bound,
      setDesignDepth: action.bound,
      setScenarioDataField: action.bound,
      handleValueChange: action.bound,
      resetScenarioDataToDefaults: action.bound,
      fetchMockData: action.bound,
      fetchJsonDocumentByName: action.bound,
      createScenarioData: action.bound,
      updateScenarioData: action.bound,
      deleteScenarioData: action.bound,
      setSelectedScenarios: action.bound,
      setSelectedZone: action.bound,
      setTimeOfInterest: action.bound,
      setPolygonZones: action.bound,
    });
  }

  setDateTime(dateTime: string) {
    this.dateTime = dateTime;
  }

  /**
   * Silattion Forecast
   *
   * Set display volume
   * @param show
   */
  setDisplayVolume(show: boolean) {
    this.displayVolume = show;
  }

  /**
   * Siltation Forecast
   *
   * Set Dredging Cost
   * @param cost
   */
  setDredgingCost(cost: number) {
    this.dredgingCost = cost;
  }

  /**
   * Silattion Forecast
   *
   * Set Channel Category
   * @param category
   */
  setChannelCategory(category: string) {
    this.channelCategory = category;
  }

  /**
   * Siltation Forecast
   *
   * Set the designDepth obj.
   * This is the main function used in Siltation Forecast and it sorts the data.
   * @param data
   */
  setDesignDepth(data) {
    this.designDepth = {
      above: this.calculateAboveDesignElevation(data) || [],
      highTolerance: this.calculateDesignToInsuranceTolerance(data) || [],
      below: this.calculateBelowInsurance(data) || [],
    };
  }

  /**
   * Siltation Forecast
   *
   * @param data
   * @returns Above design elevation results
   */
  calculateAboveDesignElevation(data) {
    try {
      const aboveList = data.dredgingZones.map((item) => {
        return {
          name: item.name,
          threshold: item.designElevation,
        };
      });

      const aboveHighTolerance = data.results.reduce((acc, cur) => {
        aboveList.forEach((element) => {
          const date = `${this.dateTime}T00:00:00`;
          if (element.name === cur.name && cur.elevations[date] > element.threshold) {
            acc.push({
              ...cur,
              selectedElevation: cur.elevations[date],
              dredgingVolume: cur.designVolumes[date],
              exceedanceDateTime: cur.designExceedanceDate
                ? format(new Date(cur.designExceedanceDate), 'MM/yyyy')
                : 'N/A',
            });
          } else if (element.name === cur.name && Object.keys(cur.elevations).length === 0) {
            acc.push({
              ...cur,
              selectedElevation: 'No data',
              dredgingVolume: 'No data',
            });
          }
        });
        return acc;
      }, []);

      return aboveHighTolerance;
    } catch (err) {
      console.error('calculateAboveDesignDepth: ', err);
      return [];
    }
  }

  lastDate(dataObject: { [date: string]: number }, formatted = true) {
    // Note: backend guarantees correct order of key/properties
    const dateKey = Object.getOwnPropertyNames(dataObject).pop();

    if (formatted) return format(new Date(dateKey), 'MM/yyyy');

    return new Date(dateKey);
  }

  /**
   * Siltation Forecast
   *
   * @param data
   * @returns Design to Insurance tolerance results
   */
  calculateDesignToInsuranceTolerance(data) {
    try {
      const highToleranceList = data.dredgingZones.map((item) => {
        return {
          name: item.name,
          threshold: {
            min: item.insuranceElevation || item.dredgeElevation,
            max: item.designElevation,
          },
        };
      });

      const highTolerance = data.results.reduce((acc, cur) => {
        highToleranceList.forEach((element) => {
          const date = `${this.dateTime}T00:00:00`;
          if (
            element.name === cur.name &&
            element.threshold.min < cur.elevations[date] &&
            cur.elevations[date] <= element.threshold.max
          ) {
            acc.push({
              ...cur,
              selectedElevation: cur.elevations[date],
              dredgingVolume: cur.insuranceVolumes[date],
              // exceedanceDateTime: `Beyond ${this.lastDate(cur.elevations)}`,
              exceedanceDateTime: cur.designExceedanceDate
                ? format(new Date(cur.designExceedanceDate), 'MM/yyyy')
                : `Beyond ${cur.maintenanceVolumes ? this.lastDate(cur.maintenanceVolumes) : 'Evaluation Date'}`,
            });
          }
        });
        return acc;
      }, []);

      return highTolerance;
    } catch (err) {
      console.error('calculateDesignToHighTolerance: ', err);
      return [];
    }
  }

  /**
   * Siltation Forecast
   *
   * @param data
   * @returns Below Insurance results
   */
  calculateBelowInsurance(data) {
    try {
      const belowList = data.dredgingZones.map((item) => {
        return {
          name: item.name,
          threshold: item.insuranceElevation || item.dredgeElevation,
        };
      });

      const belowLowTolerance = data.results.reduce((acc, cur) => {
        belowList.forEach((element) => {
          const date = `${this.dateTime}T00:00:00`;
          if (element.name === cur.name && cur.elevations[date] <= element.threshold) {
            acc.push({
              ...cur,
              selectedElevation: cur.elevations[date],
              dredgingVolume: 'N/A',
              exceedanceDateTime: `Beyond ${
                cur.maintenanceVolumes ? this.lastDate(cur.maintenanceVolumes) : 'Evaluation Date'
              }`,
            });
          }
        });
        return acc;
      }, []);

      return belowLowTolerance;
    } catch (err) {
      console.error('calculateDesignBelow: ', err);
      return [];
    }
  }

  /**
   * Fetch mock data as needed for initial front end development
   */
  fetchMockData() {
    const client = this.allStores.appStateStore.session.customerCode === 'PortOfBrisbane' ? 'PBPL' : 'GCWA';
    fetch(`${process.env.REACT_APP_ROOT_URL}/mockData/${client}.json`)
      .then((res) => res.json())
      .then((res) => (this.portSustainabilityData = res as any))
      .catch((err) => console.warn('FetchData: ', err));
  }

  fetchJsonDocumentByName(jsonDocumentName: string = 'siltation-forecast', dataSourceName: string = 'sustainability') {
    const dataSource = this.allStores.configStore.jsonDocumentsDataSources[dataSourceName];

    dataSource.connection = `MarineAid-JsonDocuments-Sustainability-${this.allStores.configStore.customerCode}`;

    fetchJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, jsonDocumentName).subscribe(
      (res) => {
        console.log({ res: JSON.parse(res.data) });
        this.portSustainabilityData = JSON.parse(res.data);
      },
    );
  }

  /**
   * Creates a JSON document scenario
   * @param scenarioContext
   * @param dataSourceName
   * @param id
   * @param freshOpen
   */
  createScenarioData(
    scenarioContext: PortSustainabilityDTO,
    dataSourceName: string = 'sustainability',
    id?: string | number,
    freshOpen?: boolean,
  ) {
    const newScenario = {
      ...this.scenarioData,
      data: JSON.stringify(scenarioContext),
    };
    let dataSource = this.allStores.configStore.jsonDocumentsDataSources[dataSourceName];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    postJsonDocuments(dataSource, this.allStores.appStateStore.session.token.accessToken, newScenario);

    // this.scenarioData = scenarioContext;

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.scenarioData);
    }
  }

  /**
   * updates a JSON document scenario
   * @param scenarioContext
   * @param dataSourceName
   */
  updateScenarioData(scenarioContext: PortSustainabilityScenarioContainer, dataSourceName: string = 'sustainability') {
    const updateScenario = {
      ...scenarioContext,
      data: JSON.stringify(scenarioContext.data),
    };

    let dataSource = this.allStores.configStore.jsonDocumentsDataSources[dataSourceName];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    updateJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, updateScenario);

    this.scenarioData = scenarioContext;
  }

  /**
   * Delete a JSON document scenario
   * @param scenarioContext
   * @param dataSourceName
   */
  deleteScenarioData(scenarioContext: PortSustainabilityScenarioContainer, dataSourceName: string = 'sustainability') {
    let dataSource = this.allStores.configStore.jsonDocumentsDataSources[dataSourceName];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    deleteJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, scenarioContext.fullName);
  }

  /**
   * Execute a job
   * @param dataSourceName
   * @param fullName
   * @param taskId
   * @param hostGroup
   * @returns
   */
  jobExecution(
    dataSourceName: string = 'sustainability',
    fullName: string,
    taskId: string = 'DHI.MarineAid.CodeWorkflow.Workflows.BlobNado.Sustainability',
    hostGroup: string = 'BlobNado',
  ) {
    let dataSource = {
      ...this.allStores.configStore.jsonDocumentsDataSources[dataSourceName],
      connection: 'MarineAid-Jobs-CodeBased',
    };

    const { customerCode } = this.allStores.appStateStore.session;

    const parameters = {
      ClientName: customerCode,
      ScenarioId: fullName,
    };

    return executeJob(
      dataSource,
      this.allStores.appStateStore.session.token.accessToken,
      taskId,
      parameters,
      hostGroup,
    );
  }

  /**
   * Set main scenario
   * you can see it on the Debugger Viewer
   * @param scenarioContext
   * @param freshOpen
   */
  setScenarioData(scenarioContext: PortSustainabilityScenarioContainer, freshOpen?: boolean) {
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    this.scenarioData = scenarioContext;

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.scenarioData);
    }
  }

  /**
   * Set Scenario Data field
   * Also update the Debugger Viewer
   * @param dataConfig
   * @param fieldName
   * @param fieldValue
   * @param intl
   * @param inServerUnit
   * @param noValidation
   */
  setScenarioDataField(
    dataConfig: any,
    fieldName: string,
    fieldValue: any,
    intl: IntlShape,
    inServerUnit?: boolean,
    noValidation = false,
  ) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.scenarioData.data, `$.${fieldName}`, () => fieldValue);
  }

  /**
   * Sets initial/default JSON document data
   * @returns
   */
  setJsonDocumentData() {
    return {
      fullName: uuidv4(),
      added: new Date(),
      dateTime: '',
      data: this.initialDataState,
      permissions: [
        {
          principals: ['Administrators', 'Editors', 'Users'],
          operation: 'read',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'update',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'delete',
        },
      ],
    };
  }

  /**
   * Every field should have this fn as onChange props as it takes care of update the scenario
   * @param e
   * @param dataConfig
   * @param newValue
   * @param fieldName
   * @param intl
   * @param validators
   * @param noValidation
   */
  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    newValue?: any,
    fieldName?: string,
    intl?: IntlShape,
    validators?: Validator,
    noValidation = false,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue, intl, false, noValidation);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    listFieldValue: ListFieldValue[],
    intl: IntlShape,
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName, intl);
    });
  }

  /**
   * Reset scenario to inital values
   * @param intl
   * @param dataConfig
   */
  resetScenarioDataToDefaults(intl: any, dataConfig: any) {
    const newContext = this.setJsonDocumentData();

    setDefaultValues(newContext.data, dataConfig, intl);
    this.setScenarioData(newContext, true);
  }

  /**
   * List of multiple scenarios
   * @param scenarios
   */
  setSelectedScenarios(scenarios: Scenario[]) {
    this.selectedScenarios = scenarios;
  }

  /**
   * Set zone when user clicks on the polygon on Channel Planner
   * @param zone
   */
  setSelectedZone(zone: string) {
    this.selectedZone = zone;
  }

  setTimeOfInterest(time: string) {
    this.timeOfInterest = time;
  }

  setPolygonZones(zones: any[]) {
    this.polygonZones = zones;
  }
}
