import {
  Current,
  EnvironmentalConditions,
  Mooring,
  Port,
  Tide,
  Vessel,
  VesselScenario,
  Wave,
  Wind,
} from '../../interfaces/VesselScenario';

// Can' we 'extends' from the DTO? so we keep it DRY
const VesselBlueprint: Vessel = {
  vesselName: '', // FleetManager input, validated by ScenarioConfig
  vesselType: null, // FleetManager input
  vesselTypeName: '', // set based on the FleetManager Input
  imo: '', // FleetManager input, validated by ScenarioConfig
  teu: null, // FleetManager input, validated by ScenarioConfig
  hatchHeightAboveMainDeck: null, // FleetManager input, validated by ScenarioConfig
  lineProfiles: [], // FleetManager input, validated by ScenarioConfig
  tailProfiles: [], // FleetManager input, validated by ScenarioConfig
  winchProfiles: [], // FleetManager input, validated by ScenarioConfig
  fairleads: [], // FleetManager input, validated by ScenarioConfig
  winches: [], // FleetManager input, validated by ScenarioConfig
  bittProfiles: [], // FleetManager input, validated by ScenarioConfig
  pedestalFairleads: [], // FleetManager input, validated by ScenarioConfig
  loa: null, // FleetManager input, validated by ScenarioConfig
  deckLength: null, // FleetManager input, validated by ScenarioConfig
  lpp: null, // FleetManager input, validated by ScenarioConfig
  beam: null, // FleetManager input, validated by ScenarioConfig
  bridgeToBow: null, // FleetManager input, validated by ScenarioConfig
  bridgeHeight: null, // FleetManager input, validated by ScenarioConfig
  bridgeLength: null, // FleetManager input, validated by ScenarioConfig
  draftForeValue: null, // User input, validated by ScenarioConfig
  draftMidValue: null, // User input, validated by ScenarioConfig
  draftAftValue: null, // User input, validated by ScenarioConfig
  draftType: 1, // User input through radio buttons. 1 is value where draftValue is visible, 2 is range where draftMin and draftMax are visible in a slider
  draftMin: null, // User input, validated by ScenarioConfig
  draftMax: null, // User input, validated by ScenarioConfig
  draftBinSize: null, // Admin input, validated by ScenarioConfig
  displacement: null, // User input, validated by ScenarioConfig
  deadweightTonnage: null, // from fleet manager, not visible
  freeboard: null, // calculated from user inputs
  geometricCentreHeight: null, // User input, validated by ScenarioConfig
  mouldedDepth: null, // User input, validated by ScenarioConfig
  heightOfPilotHouse: null, // User input, validated by ScenarioConfig
  heightAbovePilotHouse: null, // User input, validated by ScenarioConfig
  gyrationRadiiX: null, // User input, validated by ScenarioConfig
  gyrationRadiiY: null, // User input, validated by ScenarioConfig
  gyrationRadiiZ: null, // User input, validated by ScenarioConfig
  capacityTiersAboveDeck: null, // FleetManager input
  capacityTiersBelowDeck: null, // FleetManager input
  numberOfContainerTiers: null, // User input, validated by ScenarioConfig
  containerHeight: 2.95, // In meters. Default height for containers - MAPR instructed change to 2.95 20/4/2020
  blockCoefficient: 0,
  deckPlane: [],
  summerDraft: 0,
  totalHeightAboveWaterLevel: null,
  containerHeightAboveWl: null,
  transversalWindageArea: null,
  longitudinalWindageArea: null,
  transversalWindageAreaMin: null,
  transversalWindageAreaMax: null,
  longitudinalWindageAreaMin: null,
  longitudinalWindageAreaMax: null,
};

const PortBlueprint: Port = {
  name: '',
  fenderProfiles: [], // PortData input
  fenders: [], // PortData input, reduced to whats needed
  constantTensionWinchProfiles: [], // port data input
  bollardProfiles: [], // PortData input
  bollards: [], // PortData input, reduced to whats needed
  bollardsUnused: [], // PortData input, reduced to whats needed
  waterTemperature: null, // User input, validated by ScenarioConfig
  airTemperature: null, // User input, validated by ScenarioConfig
  salinity: null, // Admin input, validated by ScenarioConfig
  varyPretension: false, // Vary pretension on lines to converge motions.
  motionCriteria: [],
  utmProjection: null,
  waveMatrixConditionPaths: [],
  constantTensionWinchLineProfiles: [],
};

const MooringBlueprint: Mooring = {
  berthName: '', // The specific berth as a string. Set through a drop down. The drop down is populated based on PortData
  terminalName: '', // The specific berth as a string. Set through a drop down. The drop down is populated based on PortData
  motionType: 0, // The reference point for calculating motions, either centre of gravity or manifold.
  primaryBerthMarker: null, // Chosen by the user
  bowMarker: null, // Chosen by the user
  berthMarker: null, // Chosen by the user
  sternMarker: null, // Chosen by the user
  portDock: false, // Chosen by the user
  mooredHeading: null, // it's the angle of the heading of the vessel. Has to be calculated based on the mooring.berthName and the mooring.portDock
  longitude: null,
  latitude: null,
  defaultMooringArrangementName: '',
  mooringArrangementName: '',
  maxFenderThickness: null, // Set when vessel moors using fender within vessel.
  mooringArrangements: { '': [] }, // A list of mooring arrangement objects. Populated from the FleetManager when scenario.vessel.vesselName is chosen (~mooring arrangement)
  dynamic: true, // Chosen by user, initialized through ScenarioConfig
  startTime: null, // Only shown if scenario.mooring.dynamic is true. Default to Now (offset by TimeZoneOffset)
  endTime: null, // Only shown if scenario.mooring.dynamic is true. Default to Now+1 day (offset by TimeZoneOffset)
  linePretensionType: 1, // User input through radio buttons. 1 is value where *Value is visible, 2 is range where *Min and *Max are visible in a slider
  linePretensionValue: 10, // User input, validated by ScenarioConfig
  linePretensionMin: null, // User input, validated by ScenarioConfig
  linePretensionMax: null, // User input, validated by ScenarioConfig
  linePretensionBinSize: 2, // Admin input, validated by ScenarioConfig
  outreachBeyondBeam: null,
  spreaderClearance: null,
  underKeelClearance: null,
  dredgeDepth: null,
  dredgeDepthMsl: 0,
  maxBeamExceeded: false,
  maxLoaExceeded: false,
  deckAboveWharf: null,
  deckAboveWharfMin: null,
  deckAboveWharfMax: null,
  calculatedFields: [],
};

const CurrentBlueprint: Current = {
  type: 1, // User input through radio buttons. 1 is value where *Value is visible, 2 is range where *Min and *Max are visible in a slider
  speedValue: 0,
  speedBinSize: null,
  speedMin: null,
  speedMax: null,
  speedTimeseries: null,
  directionBinSize: null,
  directionValue: 0,
  directionMin: null,
  directionMax: null,
  directionTimeseries: null,
};

const WindBlueprint: Wind = {
  type: 1, // User input through radio buttons. 1 is value where *Value is visible, 2 is range where *Min and *Max are visible in a slider
  speedValue: 0,
  speedBinSize: null,
  speedMin: null,
  speedMax: null,
  speedTimeseries: null,
  directionBinSize: null,
  directionValue: 0,
  directionMin: null,
  directionMax: null,
  directionTimeseries: null,
  windSpectraType: 2,
  windSpeedTimeSpan: 3600,
};

const TideBlueprint: Tide = {
  type: 1, // User input through radio buttons. 1 is value where *Value is visible, 2 is range where *Min and *Max are visible in a slider
  value: null,
  binSize: null,
  min: null,
  max: null,
  timeseries: null,
};

const WaveBlueprint: Wave = {
  type: 1, // User input through radio buttons. 1 is value where *Value is visible, 2 is range where *Min and *Max are visible in a slider
  heightValue: 0,
  heightBinSize: null,
  heightMin: null,
  heightMax: null,
  heightTotalTimeseries: null,
  periodValue: null,
  periodBinSize: 2,
  periodMin: null,
  periodMax: null,
  periodTotalTimeseries: null,
  directionValue: 0,
  directionBinSize: null,
  directionMin: null,
  directionMax: null,
  directionTotalTimeseries: null,
  directionalStandardDeviationBinSize: null,
  // Wave matrix conditions
  timeseries: null,
  scaleFactorTimeseries: null,
  ensembleTimeseries: null,
  index: -1,
  fileName: null,
};

const EnviroBlueprint: EnvironmentalConditions = {
  wave: WaveBlueprint,
  current: CurrentBlueprint,
  wind: WindBlueprint,
  tide: TideBlueprint,
};

const VesselScenarioBlueprint: VesselScenario = {
  name: '',
  userName: '',
  debug: false,
  runAsDebug: true,
  version: null,
  usesCrossScenarioAwareness: false,
  port: PortBlueprint,
  mooring: MooringBlueprint,
  vessel: VesselBlueprint,
  environmentalConditions: EnviroBlueprint,
  results: undefined,
  autoGeneratedEvent: false,
  debugPortData: false,
};

export default VesselScenarioBlueprint;
export { VesselBlueprint };
