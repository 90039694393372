import { fetchUrl } from '@dhi/react-components';
import { isEqual, round } from 'lodash';
import { action, makeObservable, observable, runInAction, transaction } from 'mobx';
import { getScenariosWithDateOverlaping } from '../helpers/getScenariosWithDateOverlaping';
import {
  destinationPoint,
  getBerthAngle,
  getBerthToShipAngleTN,
  getDistanceCoords,
  getHeadingAngleTN,
  getVesselCentreOnVessel as getVesselCentreOnBerth,
  isPointToLeft,
} from '../helpers/mapHelpers';
import { Diagnostics } from '../interfaces/Config';
import { Berth, Fender, PortData, Terminal } from '../interfaces/PortData';
import { FenderProfile, Line, VesselScenarioContainer } from '../interfaces/VesselScenario';
import { RootStore } from './RootStore';
import { AllBollard, CrossAwarenessScenario, VisibleScenario } from './types';

interface FenderConnectivity extends Fender {
  index: number;
  point: number[];
  berthName: string;
  fenderOffset: number;
}

export class PortStore {
  private allStores: RootStore;
  portData: PortData;

  allBollards: AllBollard[] = [];
  disabledBollards: AllBollard[] = [];

  hasPortData = false;

  constructor(allStores: RootStore) {
    makeObservable(this, {
      allBollards: observable,
      disabledBollards: observable,
      hasPortData: observable,
      fetchPortData: action.bound,
      setInitialBollards: action.bound,
    });

    this.allStores = allStores;
  }

  // @computed
  // get doubleCount() {
  //   return this.count * 2;
  // }

  fetchPortData = (customerCode: string | undefined) => {
    if (!customerCode) {
      console.log(`Resetting portData.`);
      this.portData = null;
      this.hasPortData = false;

      return;
    }

    let customerCodePortData = '';
    console.log(`Fetching portData for ${customerCode}`);
    console.log(process.env);
    if (
      process.env?.REACT_APP_PORTDATAHACK &&
      process.env.REACT_APP_SYS_ENVIRONMENT === 'Dev' &&
      process.env.REACT_APP_PORTDATAHACK.includes(customerCode) &&
      this.allStores.appStateStore.session.user.metadata.debug
    ) {
      customerCodePortData = `${customerCode}-dev`;
    } else {
      customerCodePortData = customerCode;
    }
    const getPortData = () =>
      fetchUrl(
        `${process.env.REACT_APP_PORTDATA_URL.replace('{customer}', customerCodePortData)}?cacheBust=${Math.round(
          new Date().getTime() / 1000,
        )}`,
      );

    getPortData().subscribe((response) => {
      runInAction(() => {
        this.portData = response;

        this.setInitialBollards();

        this.hasPortData = true;
      });
    });
  };

  mslPortDatum(terminalName: string): number {
    const terminalUse = this.portData.terminals.find((terminal) => terminal.terminalName === terminalName);
    if (terminalUse && terminalUse.mslPortDatum !== null && terminalUse.mslPortDatum !== undefined) {
      return terminalUse.mslPortDatum;
    }

    return this.portData.mslPortDatum;
  }

  /**
   * This function returns all berths from the port data in a flat list
   * @param {PortStore} portStore The portStore
   */
  getAllBerths = () => {
    const berths = [];

    this.portData.terminals.forEach((terminal) => {
      terminal.berths.forEach((berth) => {
        berth.terminalName = terminal.terminalName;
        berths.push(berth);
      });
    });

    return berths;
  };

  /**
   * This function gets the berth name selected and finds the berthRulerOffSet property
   * @param {string} berthName The name of the berth to find in portdata
   */
  getBerthRulerPosition = (berthName: string): number => {
    const berthRulerPosition = 10;
    const berth = this.getAllBerths().find((berth) => berth.berthName === berthName);

    return berth?.berthRulerOffSet ?? berthRulerPosition;
  };

  /**
   * This function gets the portdata object and the berth name and finds the berth object by such name
   * @param {PortStore} portStore The portStore
   * @param {string} berthName The name of the berth to find in portdata
   */
  getBerth = (berthName: string): Berth | undefined =>
    this.getAllBerths().find((berth) => {
      if (berth.berthName === berthName) {
        return berth;
      }

      return null;
    });

  /**
   * This function gets the portdata object and the terminal name and finds the terminal object by such name
   * @param {PortStore} portStore The portStore
   * @param {string} terminalName The name of the berth to find in portdata
   */
  getTerminal = (terminalName: string): Terminal | undefined =>
    this.portData.terminals.find((terminal) => {
      if (terminal.terminalName === terminalName) {
        return terminal;
      }

      return null;
    });

  /**
   * This function returns all fenders from the port data in a flat list
   * @param {PortStore} portStore The portStore
   */
  getAllFenders = () => {
    let fenders: FenderConnectivity[] = [];

    let index = -1;

    this.portData.terminals.forEach((terminal) => {
      terminal.berths.forEach((berth) => {
        fenders = fenders.concat(
          berth.fenders.map((fender) => {
            index++;

            return {
              ...fender,
              index,
              point: fender.point.concat(
                fender.zAboveWharfDeck + berth.wharfHeightAbovePortDatum - this.mslPortDatum(terminal.terminalName),
              ),
              berthName: berth.berthName,
              fenderOffset: fender.fenderOffset,
            };
          }),
        );
      });
    });

    return fenders;
  };
  /**
   * This function returns all fenders from the port data in a flat list
   * @param {FenderProfile} fenderProfiles The fender profiles
   */
  getAllFendersWithData = (fenderProfiles: FenderProfile[]) => {
    let fenders = [];

    let index = -1;

    this.portData.terminals.forEach((terminal) => {
      terminal.berths.forEach((berth) => {
        let direction = getBerthAngle(berth);

        // Turn berth 180 if berth is not to the right, eg on other side of line.
        if (!berth.berthIsToTheRight) {
          direction = direction + 180;
          if (direction > 360) {
            // Fix for angle > 360
            direction = direction - 360;
          }
        }

        fenders = fenders.concat(
          berth.fenders.map((fender) => {
            index++;
            const fenderProfile = fenderProfiles.find((profile) => profile.fenderProfileId === fender.profileId);

            return {
              ...fender,
              index,
              point: fender.point.concat(
                fender.zAboveWharfDeck + berth.wharfHeightAbovePortDatum - this.mslPortDatum(terminal.terminalName),
              ),
              berthName: berth.berthName,
              direction: direction,
              thickness: fenderProfile.thickness,
              fenderOffset: fender.fenderOffset,
            };
          }),
        );
      });
    });

    return fenders;
  };

  /**
   * Sets all bollards into the system.
   */
  setInitialBollards = () => {
    transaction(() => {
      this.allBollards.splice(0);
      this.disabledBollards.splice(0);
      let index = -1;

      this.portData.terminals.forEach((terminal) => {
        // Add bollards in the terminal:
        terminal?.sharedBollards
          // In some cases, for instance with HPA they can have status=planned
          ?.filter((b) => !b.status || b.status === 'enabled' || b.status === 'planned')
          ?.forEach((portBollard) => {
            const bollardProfile = this.portData.bollardProfiles.find(
              (bp) => bp.bollardProfileId === portBollard.profileId,
            );

            portBollard.associatedProfile = bollardProfile;

            index++;

            portBollard.point[2] =
              portBollard.zAboveWharfDeck + terminal.wharfHeightAbovePortDatum - this.mslPortDatum(terminal.terminalName);

            // Do not add bollards which are already in the list (this can occur on certain berths which share bollards in their bollard list)
            if (!this.allBollards.some((b) => isEqual(portBollard.point, b.point))) {
              this.allBollards.push({
                ...portBollard,
                index,
                berthName: terminal.terminalName,
              } as AllBollard);
            }
          });
        // Add bollards for each berth:
        terminal.berths.forEach((berth) => {
          berth.bollards
            // In some cases, for instance with HPA they can have status=planned
            .filter((b) => !b.status || b.status === 'enabled' || b.status === 'planned')
            .forEach((portBollard) => {
              const bollardProfile = this.portData.bollardProfiles.find(
                (bp) => bp.bollardProfileId === portBollard.profileId,
              );

              portBollard.associatedProfile = bollardProfile;

              index++;

              portBollard.point[2] =
                portBollard.zAboveWharfDeck + berth.wharfHeightAbovePortDatum - this.mslPortDatum(terminal.terminalName);

              // Do not add bollards which are already in the list (this can occur on certain berths which share bollards in their bollard list)
              if (!this.allBollards.some((b) => isEqual(portBollard.point, b.point))) {
                this.allBollards.push({
                  ...portBollard,
                  index,
                  berthName: berth.berthName,
                } as AllBollard);
              }
            });
        });
      });

      index = -1;
      this.portData.terminals.forEach((terminal) => {
        terminal.berths.forEach((berth) => {
          berth.bollards
            // In some cases, for instance with HPA they can have status=planned
            .filter((f) => f.status === 'disabled')
            .forEach((portBollard) => {
              const bollardProfile = this.portData.bollardProfiles.find(
                (bp) => bp.bollardProfileId === portBollard.profileId,
              );

              portBollard.associatedProfile = bollardProfile;

              index++;

              portBollard.point[2] =
                portBollard.zAboveWharfDeck + berth.wharfHeightAbovePortDatum - this.mslPortDatum(terminal.terminalName);

              // Do not add bollards which are already in the list (this can occur on certain berths which share bollards in their bollard list)
              if (!this.disabledBollards.some((b) => isEqual(portBollard.point, b.point))) {
                this.disabledBollards.push({
                  ...portBollard,
                  index,
                  berthName: berth.berthName,
                } as AllBollard);
              }
            });
        });
      });
    });
  };
  /**
   * Get All adjacent berth names to a berth - Including own berth.
   * @param berthName
   * @param allBerths
   */
  getAdjacentBerths(berthName: string, allBerths: Berth[]): string[] {
    let adjacentBerths: string[] = [];
    adjacentBerths.push(berthName);
    console.log(`Adding Own berth:${berthName}`);

    let allBerthNames = allBerths.map((berth) => berth.berthName);

    // Add adjacent berths including adjacent to adjacent all the way down.
    for (let adjBerth of adjacentBerths) {
      let berthAdjacent = allBerths.find((berth) => berth.berthName === adjBerth);
      for (let adjBerth2 of berthAdjacent?.adjacentBerths.filter(String)) {
        if (!adjacentBerths.includes(adjBerth2) && allBerthNames.includes(adjBerth2)) {
          adjacentBerths.push(adjBerth2);
          console.log(`Adding Adjacent berth:${adjBerth2}`);
        }
      }
    }

    return adjacentBerths;
  }
  /**
   * Get line availability for bollard, accounting for all existing scenarios (with time consideration if applicable) given an input scenario.
   * @param bollard Bollard to check
   * @param scenarioDataContainer Incoming data container
   * @param diagnostics true/false
   */
  getAvailableLines = (
    bollard: AllBollard,
    scenarioDataContainer: VesselScenarioContainer,
    ignoreOwnLines: boolean,
    diagnostics: Diagnostics,
  ): number => {
    let availableLines = bollard.associatedProfile.maxLines;
    let allScenarios: VisibleScenario[] = null;

    if (
      scenarioDataContainer.data.usesCrossScenarioAwareness &&
      scenarioDataContainer.data.mooring.startTime &&
      scenarioDataContainer.data.mooring.endTime &&
      this.allStores.appStateStore.visibleScenarios &&
      this.allStores.appStateStore.visibleScenarios.length > 1
    ) {
      allScenarios = getScenariosWithDateOverlaping(
        scenarioDataContainer,
        this.allStores.appStateStore,
        this.allStores.vesselScenarioStore,
      );
      // Only bring in where necessary. Otherwise can overwhelm console
      // if (diagnostics?.validationEngine) {
      //   console.log(
      //     `${allScenarios.length} scenarios found between ${scenarioDataContainer.data.mooring.startTime} – ${
      //       scenarioDataContainer.data.mooring.endTime
      //     } %c(${allScenarios.map((x) => x.data.name).join(',')})`,
      //     'color:#888',
      //   );
      // }
    } else {
      allScenarios = [scenarioDataContainer];
    }

    const processedScenarios = [];

    this.portData.terminals.forEach((terminal) => {
      terminal.berths.forEach((berth) => {
        const bollardMatchingPoint = berth.bollards.filter(
          (x) => bollard.point[0] === x.point[0] && bollard.point[1] === x.point[1],
        );

        bollardMatchingPoint.forEach((portBollard) => {
          // Iterate each scenario within each bollard
          if (allScenarios && allScenarios.length > 0) {
            let usedLines = 0;

            allScenarios.forEach((scenario: VesselScenarioContainer) => {
              const usingScenario = scenario;

              if (!ignoreOwnLines || usingScenario.id !== scenario.id) {
                if (processedScenarios.indexOf(scenario.id) < 0) {
                  // Have we already processed this scenario?
                  processedScenarios.push(scenario.id);

                  usingScenario.data.mooring.mooringArrangements[usingScenario.data.mooring.mooringArrangementName]
                    .filter((line) => line.bollardIndex != null)
                    .forEach((line) => {
                      const lineBollard = this.getConnectedBollard(line);

                      if (
                        lineBollard &&
                        lineBollard.point[0] === portBollard.point[0] &&
                        lineBollard.point[1] === portBollard.point[1]
                      ) {
                        usedLines++;

                        // if (usingScenario.data.name === 'GRON CSA 2' && lineBollard.name === 'W0507') {
                        // console.log(
                        //   `VALIDATE(usedLines=${usedLines}) W0507 - ${usingScenario.data.name} - ${usingScenario.data.mooring.mooringArrangementName}`,
                        //   usingScenario.data.mooring.mooringArrangements[
                        //     usingScenario.data.mooring.mooringArrangementName
                        //   ].map((l) => l.bollardIndex),
                        // );
                        // console.log(
                        //   usingScenario.data.mooring.mooringArrangementName,
                        //   `${usingScenario.data.name} = ${line.id}/${
                        //     lineGroupKeys[line.lineType - 1]
                        //   } - bollardName:${lineBollard.name} bollardIndex:${line.bollardIndex}`,
                        // );
                        // }
                      }
                    });
                }
              }
            });

            // diagnostics.validationEngine
            // if (usedLines > 0) {
            //   console.log(`${portBollard.name} ${usedLines > 0 ? `*${usedLines}` : ''}`);
            // }
            if (usedLines > 0) {
              availableLines = portBollard.associatedProfile.maxLines - usedLines;
            }
          }
        });
      });
    });

    return availableLines;
  };

  getConnectedBollard = (line: Line) => {
    return this.allBollards[line.bollardIndex];
  };

  /**
   * Get scenarios adjacent (aka. two closest) to scenario provided.
   * @param scenario Scenario to test
   * @param diagnostics true/false
   */
  getAdjacentScenarios = (scenario: VisibleScenario, diagnostics: Diagnostics): CrossAwarenessScenario[] => {
    let allDistances = [];

    let allScenarios: VisibleScenario[] = [];

    // Find all scenarios which intersect incoming scenario's start/end time
    if (
      scenario.data.usesCrossScenarioAwareness &&
      scenario.data.mooring.startTime &&
      scenario.data.mooring.endTime &&
      this.allStores.appStateStore.visibleScenarios &&
      this.allStores.appStateStore.visibleScenarios.length > 1
    ) {
      allScenarios = [...[scenario], ...this.allStores.appStateStore.visibleScenarios];

      allScenarios = getScenariosWithDateOverlaping(
        scenario,
        this.allStores.appStateStore,
        this.allStores.vesselScenarioStore,
      );
    }

    if (this.allStores.vesselScenarioStore.scenarioData.data.usesCrossScenarioAwareness) {
      const berth = this.getBerth(scenario.data.mooring.berthName);
      const headingAngle = getHeadingAngleTN(scenario.data.mooring, berth);
      const vessel1VesselCentreOnBerth = getVesselCentreOnBerth(berth, scenario.data.mooring, scenario.data.vessel, 0);
      const berthToShipAngle = getBerthToShipAngleTN(berth);
      const primaryVesselPoint = destinationPoint(
        vessel1VesselCentreOnBerth,
        scenario.data.vessel.loa / 2,
        headingAngle,
      );
      const endOfBeamPoint = destinationPoint(primaryVesselPoint, scenario.data.vessel.beam, berthToShipAngle);

      allScenarios.forEach((otherScenario: VisibleScenario) => {
        // Not itself
        if (otherScenario.id !== scenario.id) {
          const otherBerth = this.getBerth(otherScenario.data.mooring.berthName);

          const vessel2VesselCentreOnBerth = getVesselCentreOnBerth(
            otherBerth,
            otherScenario.data.mooring,
            otherScenario.data.vessel,
            0,
          );
          const toTheRight = !isPointToLeft(vessel1VesselCentreOnBerth, endOfBeamPoint, vessel2VesselCentreOnBerth);

          const distanceBetweenCenters = getDistanceCoords(
            [vessel1VesselCentreOnBerth.lon, vessel1VesselCentreOnBerth.lat],
            [vessel2VesselCentreOnBerth.lon, vessel2VesselCentreOnBerth.lat],
          );
          const halfLoas = scenario.data.vessel.loa / 2 + otherScenario.data.vessel.loa / 2;
          const distance = distanceBetweenCenters - halfLoas;
          const collision = distance < 0;

          allDistances.push({
            id: otherScenario.id,
            name: otherScenario.data.name,
            // debugDots: [[otherVesselPoint.lat, otherVesselPoint.lon]],
            distance: distance,
            collision,
            toTheRight,
          });
        }
      });

      allDistances = allDistances.sort((a, b) => a.distance - b.distance);
      const reducedScenarios = allDistances;

      if (
        diagnostics?.validationEngine &&
        scenario.data.usesCrossScenarioAwareness &&
        scenario.data.mooring.startTime &&
        scenario.data.mooring.endTime
      ) {
        console.log(
          `${reducedScenarios.length} adjacent scenarios found between ${scenario.data.mooring.startTime} – ${
            scenario.data.mooring.endTime
          } \n%c${reducedScenarios.map((x) => `${x.scenarioName}(${round(x.distance, 2)}m)`).join(',')}`,
          'color:#888',
        );
      }

      return reducedScenarios; // take top 2
    } else {
      return null;
    }
  };
}
