import { deleteJsonDocument, executeJob, fetchTimeseriesValues, postJsonDocuments } from '@dhi/react-components';
import { add, format } from 'date-fns';
import jp from 'jsonpath';
import { cloneDeep } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import {
  ListFieldValue,
  Validator,
  displayToField,
  getConfigSection,
  getFieldConfig,
} from 'src/components/SharedLibrary';
import {
  PortSustainabilityScenarioContainer,
  SurveyScenarioContainer,
} from 'src/modules/PortSustainability/__models/PortSustainabilityScenario';
import setDefaultValues from 'src/modules/PortSustainability/__utility/setDefaultValues';
import { TIMESERIES_DATE_FORMAT } from 'src/modules/PortSustainability/constants';
import { v4 as uuidv4 } from 'uuid';
import { PSScenarioStore } from './PSScenarioStore';
import { RootStore } from './RootStore';

export class PSDataPortalStore extends PSScenarioStore {
  initialSurveyData = {
    name: '',
    description: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    inputDataResolution: '',
    version: 0,
    dredgeDate: format(new Date(), 'yyyy-MM-dd'),
    dredgingZones: [],
  };

  loadedScenarioId = '';
  supportToken = '';
  initialSurvey = {
    added: new Date(),
    dateTime: '',
    fullName: '',
    data: this.initialSurveyData,
    permissions: [],
  } as SurveyScenarioContainer;

  layerOption = '';
  layerStyle = '';
  boundingBox = [];
  imageLayer = null;
  dataPortalTimeseries = null;
  mapViewWindow = {
    width: 0,
    height: 0,
  };
  mapLocationInfo = null;
  tabId = 0;

  surveyData = this.initialSurvey;

  constructor(allStores: RootStore) {
    super(allStores);

    makeObservable(this, {
      surveyData: observable,
      layerOption: observable,
      layerStyle: observable,
      boundingBox: observable,
      imageLayer: observable,
      dataPortalTimeseries: observable,
      mapViewWindow: observable,
      mapLocationInfo: observable,
      tabId: observable,
      loadedScenarioId: observable,
      supportToken: observable,

      setLayerOption: action.bound,
      setLayerStyle: action.bound,
      setBoundingBox: action.bound,
      setImageLayer: action.bound,
      setDataPortalTimeseries: action.bound,
      setMapViewWindow: action.bound,
      setMapLocationInfo: action.bound,
      setTabName: action.bound,
      setScenarioDataField: action.bound,
      handleValueChange: action.bound,
      resetScenarioDataToDefaults: action.bound,
      createScenarioData: action.bound,
      deleteScenarioData: action.bound,
      deleteDredgeRecords: action.bound,
      deleteSurveyRecords: action.bound,
      fetchImageLayer: action.bound,
      setloadedScenarioId: action.bound,
      setSupportToken: action.bound,
    });
  }

  setSupportToken(supportToken: string) {
    this.supportToken = supportToken;
  }

  setloadedScenarioId(fullName: string) {
    this.loadedScenarioId = fullName;
  }

  setLayerOption(option: string) {
    this.layerOption = option;
  }

  setLayerStyle(style: string) {
    this.layerStyle = style;
  }

  setBoundingBox(boundingBox: number[][]) {
    this.boundingBox = boundingBox.flat();
  }

  setImageLayer(layer: any) {
    this.imageLayer = layer;
  }

  setDataPortalTimeseries(timeseries: any) {
    this.dataPortalTimeseries = timeseries;
  }

  setMapViewWindow(width: number, height: number) {
    this.mapViewWindow = {
      width,
      height,
    };
  }

  setMapLocationInfo(info: any) {
    this.mapLocationInfo = info;
  }

  setTabName(tabId: number) {
    this.tabId = tabId;
  }

  resetPortConfig() {
    this.setImageLayer(null);
    this.setDataPortalTimeseries(null);
    this.setMapLocationInfo(null);
  }

  setJsonDocumentData() {
    return {
      fullName: '',
      added: new Date(),
      dateTime: '',
      data: this.initialSurveyData,
      permissions: [
        {
          principals: ['Administrators', 'Editors', 'Users'],
          operation: 'read',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'update',
        },
        {
          principals: ['Administrators', 'Editors'],
          operation: 'delete',
        },
      ],
    };
  }

  createScenarioData(id?: string | number, freshOpen?: boolean) {
    const newScenario = {
      ...this.surveyData,
      fullName: id || uuidv4(), // check if this is needed.
      data: JSON.stringify(this.surveyData.data),
    };
    const tabName = this.tabId === 0 ? 'survey' : 'dredge';

    let dataSource = this.allStores.configStore.jsonDocumentsDataSources[tabName];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    postJsonDocuments(dataSource, this.allStores.appStateStore.session.token.accessToken, newScenario);

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.surveyData);
    }
  }

  deleteScenarioData(scenarioContext: PortSustainabilityScenarioContainer, dataSourceName: string = 'survey') {
    let dataSource = this.allStores.configStore.jsonDocumentsDataSources[dataSourceName];
    dataSource.connection = dataSource.connection.replace('[customerCode]', this.allStores.configStore.customerCode);

    deleteJsonDocument(dataSource, this.allStores.appStateStore.session.token.accessToken, scenarioContext.fullName);
  }

  deleteDredgeRecords(scenarioContext: PortSustainabilityScenarioContainer) {
    fetch(
      `${process.env.REACT_APP_BLOBNADO_DREDGE_UPLOAD_ENPOINT_URL}?clientid=${this.allStores.configStore.customerCode}&uploaderid=sustainability-dredge-loader&id=${scenarioContext.fullName}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.allStores.appStateStore.session.token.accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => console.log('dredge records deleted res:', res))
      .catch((err) => console.error('failed to delete dredge records: ', err));
  }

  deleteSurveyRecords(scenarioContext: PortSustainabilityScenarioContainer) {
    fetch(`${process.env.REACT_APP_BLOBNADO_ENPOINT_URL}/event/${scenarioContext.fullName}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.allStores.appStateStore.session.token.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => console.log('survey records deleted res:', res))
      .catch((err) => console.error('failed to delete survey records: ', err));
  }

  setScenarioData(scenarioContext: SurveyScenarioContainer, freshOpen?: boolean) {
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    this.surveyData = scenarioContext;

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.surveyData);
    }
  }

  setScenarioDataField(
    dataConfig: any,
    fieldName: string,
    fieldValue: any,
    intl: IntlShape,
    inServerUnit?: boolean,
    noValidation = false,
  ) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.surveyData.data, `$.${fieldName}`, () => fieldValue);
  }

  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    newValue?: any,
    fieldName?: string,
    intl?: IntlShape,
    validators?: Validator,
    noValidation = false,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue, intl, false, noValidation);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
    dataConfig: any,
    listFieldValue: ListFieldValue[],
    intl: IntlShape,
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName, intl);
    });
  }

  resetSurveyData() {
    this.surveyData = this.initialSurvey;
  }

  resetScenarioDataToDefaults(intl: any, dataConfig: any) {
    const newContext = this.setJsonDocumentData();

    setDefaultValues(newContext.data, dataConfig, intl);
    this.setScenarioData(newContext, true);
  }

  jobExecution(
    data: any,
    dataSourceName: string = 'sustainability',
    fullName: string,
    taskId: string = 'DHI.MarineAid.CodeWorkflow.Workflows.BlobNado.ImportBathymetryData',
    hostGroup: string = 'BlobNado',
  ) {
    let dataSource = {
      ...this.allStores.configStore.jsonDocumentsDataSources[dataSourceName],
      connection: 'MarineAid-Jobs-CodeBased',
    };
    const { customerCode } = this.allStores.appStateStore.session;

    const parameters = {
      ClientId: customerCode,
      Date: format(new Date(data.date), 'yyyyMMdd'),
      UploadId: fullName,
    };

    return executeJob(
      dataSource,
      this.allStores.appStateStore.session.token.accessToken,
      taskId,
      parameters,
      hostGroup,
    );
  }

  /**
   * Fetch Image layer used on Data Portal and Channel Planner
   * @param fullName
   * @param style
   * @param zones
   * @param BoundingBoxIsLonLat
   * @param boundingBox
   * @param width
   * @param height
   */
  fetchImageLayer(
    fullName: string,
    style: string,
    zones?: any,
    BoundingBoxIsLonLat = true,
    boundingBox = this.boundingBox,
    width = this.mapViewWindow.width,
    height = this.mapViewWindow.height,
    dataAgeThreshold = 32000,
    timeSteps?: Date[],
  ) {
    let body;
    const box = boundingBox.join(',');

    body = {
      EventId: fullName,
      Width: width,
      Height: height,
      Style: style,
      BoundingBoxIsLonLat,
      BoundingBox: box,
      DataAgeThreshold: dataAgeThreshold,
      TimeSteps: timeSteps,
    };

    if (this.layerOption === 'Survey Date') {
      body = {
        ...body,
        DateLegend: true,
        GlobalValueModifier: 'datevalue',
      };
    }

    if (zones) {
      // Channel planner part
      body = {
        ...body,
        TimeSteps: [
          this.allStores.portSustainabilityStore.timeOfInterest ||
            this.allStores.portSustainabilityStore.scenarioData.data.dredgeDate,
        ],
        Zones: zones,
      };
    }

    console.debug(`Fetching iumage layer from: ${process.env.REACT_APP_BLOBNADO_ANIMATE}`);

    fetch(process.env.REACT_APP_BLOBNADO_ANIMATE, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${this.allStores.appStateStore.session.token.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.setSupportToken(res.headers.get('support-token'));
        return res.json();
      })
      .then((data) => this.setImageLayer(data));
  }

  fetchBlobNadoTimeseries(info: any) {
    fetch(
      `${process.env.REACT_APP_BLOBNADO_TIMESERIES}-${this.allStores.appStateStore.session.customerCode}/Latitude=${info.coordinate[1]};Longitude=${info.coordinate[0]};Item=Depth/values`,
      {
        headers: {
          Authorization: `Bearer ${this.allStores.appStateStore.session.token.accessToken}`,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => this.setDataPortalTimeseries(data));
  }

  /**
   * Fetch timeseries data by id
   * @param ids
   * @param name
   * @returns
   */
  fetchTimeseries(ids: string[], date: any, last?: boolean) {
    const { session } = this.allStores.appStateStore;
    const newDataSource = this.allStores.configStore.jsonDocumentsDataSources['channelPlanner']; // Make a copy

    return fetchTimeseriesValues(
      [
        {
          ...newDataSource,
          from: format(add(new Date(date), { months: -24 }), TIMESERIES_DATE_FORMAT),
          to: format(add(new Date(date), { days: 1 }), TIMESERIES_DATE_FORMAT),
          ids,
        },
      ],
      session.token.accessToken,
      last,
    ).subscribe((results) => {
      if (results.length) {
        const timeseries = {
          hasValues: true,
          dateTimes: results[0].data.map((item) => item[0]),
          values: results[0].data.map((item) => item[1]),
        };

        this.setDataPortalTimeseries(timeseries);
      }
    });
  }
}
