import AuthService, { fetchTimeseriesValues } from '@dhi/react-components';
import { Color } from '@material-ui/lab';
import { addDays, format, parseISO } from 'date-fns';
import { LatLon as UtmLatLon } from 'geodesy/utm';
import _, { cloneDeep, isEmpty, round } from 'lodash';
import { action, makeObservable, observable, transaction } from 'mobx';
import { IntlShape } from 'react-intl';
import { getFullDeckPlane } from 'src/helpers/ma/calculateConnectivity';
import { MA_ROUND_DECIMALS } from '../helpers/constants';
import { valueToDisplay } from '../helpers/conversions';
import { LineValidityType, ModuleName } from '../helpers/enums';
import { diagnosticsLineNames, getLineNames } from '../helpers/ma/lineNames';
import { getHeadingAngle, latLngHeightToUtm, latLngToUtm } from '../helpers/mapHelpers';
import { Diagnostics, Module } from '../interfaces/Config';
import { Berth, PortData } from '../interfaces/PortData';
import { Fairlead, Line, Mooring, Point2D } from '../interfaces/VesselScenario';
import GraphQLService from '../services/GraphQLService';
import { RootStore } from './RootStore';
import {
  AlertDialogVisibilityParameters,
  AllBollard,
  CrossAwarenessScenario,
  Session,
  SnackbarErrorSet,
  SnackbarItem,
  SnackbarVisibilityParameters,
  VisibleScenario,
} from './types';
import { PortScenarioValidity } from './types/portTypes';
import {
  LineIssue,
  LineValidity,
  LinesValidation,
  ProximityValidation,
  ProximityValidationItem,
  VesselScenarioValidity,
} from './types/vesselTypes';

export class AppStateStore {
  private authService: AuthService;
  private allStores: RootStore;
  observedSession: Session;
  visibleScenarios: Array<VisibleScenario>;
  snackbarParams: SnackbarVisibilityParameters;
  alertDialogParams: AlertDialogVisibilityParameters;

  private graphQLService = null;

  // *******************
  // TODO: If adding new observables here, add their reset also to resetAppState()
  currentModule: ModuleName;
  editMode: boolean;
  isNewMode: boolean;
  currentMaxForecastDate: Date | null;
  bottomPanelShowing: boolean;
  showWarnings: boolean;
  rightPanelShowing = true;
  scenarioLogShowing: boolean;
  setInputs: { [key: string]: boolean };
  currentView: Record<string, any>;
  compareScenarioId: string;
  mapDropdown = '';
  autoMoorCalculating: boolean;
  autoMoorFailed: boolean;
  autoMoorSuccesful: boolean;
  pendingAutomoorRequests: number;
  mooringArrangementModified: boolean;
  tideDataModified: boolean;
  bowMarkerModified: boolean;
  bridgeMarkerModified: boolean;
  sternMarkerModified: boolean;
  stagedCranesModified: number;
  prescreeningModified: boolean;
  bollardIndexModified: boolean;
  fairleadIndexModified: boolean;
  vesselProximityError: boolean;

  // *******************

  constructor(allStores: RootStore) {
    this.allStores = allStores;
    this.authService = new AuthService(process.env.REACT_APP_API_ENDPOINT_URL);
    this.graphQLService = new GraphQLService(this.authService.getSession().accessToken);

    makeObservable(this, {
      visibleScenarios: observable,
      observedSession: observable,
      snackbarParams: observable,
      alertDialogParams: observable,

      // *******************
      currentModule: observable,
      editMode: observable,
      isNewMode: observable,
      currentMaxForecastDate: observable,
      bottomPanelShowing: observable,
      showWarnings: observable,
      rightPanelShowing: observable,
      scenarioLogShowing: observable,
      setInputs: observable,
      currentView: observable,
      compareScenarioId: observable,
      mapDropdown: observable,
      autoMoorCalculating: observable,
      pendingAutomoorRequests: observable,
      mooringArrangementModified: observable,
      tideDataModified: observable,
      bowMarkerModified: observable,
      bridgeMarkerModified: observable,
      sternMarkerModified: observable,
      prescreeningModified: observable,
      bollardIndexModified: observable,
      fairleadIndexModified: observable,
      stagedCranesModified: observable,
      vesselProximityError: observable,
      // *******************

      setSession: action.bound,
      setCurrentModule: action.bound,
      resetAppState: action.bound,
      setupSession: action.bound,
      logout: action.bound,
      setVisibleScenarios: action.bound,
      setMaxForecastDate: action.bound,
      validatePortScenario: action.bound,
      validateVesselScenario: action.bound,
      setBottomPanelShowing: action.bound,
      setShowWarnings: action.bound,      
      setCurrentView: action.bound,
    });
  }

  get session(): Session {
    return (
      this.observedSession || {
        user: null,
        customerCode: null,
        token: {
          accessToken: null,
          refreshToken: null,
        },
      }
    );
  }

  get authorisedModules(): Module[] {
    return this.allStores.configStore.appConfig.modules.filter(
      (m) =>
        (this.session.user.metadata.modules != null ? this.session.user.metadata.modules : []).includes(m.id) &&
        this.session.user.metadata.modules.includes(m.id),
    );
  }

  setSession = (session: Session) => {
    this.observedSession = session;
  };

  setCurrentModule = (currentModule: ModuleName) => {
    this.currentModule = currentModule;
  };

  setBottomPanelShowing = (show) => {
    this.bottomPanelShowing = show;
  };

  setShowWarnings = (show: boolean) => {
    this.showWarnings = show;
  };

  resetAppState = () => {
    this.currentModule = null;
    this.isNewMode = false;
    this.editMode = false;
    this.currentMaxForecastDate = null;
    this.bottomPanelShowing = false;
    this.rightPanelShowing = true;
    this.scenarioLogShowing = false;
    this.setInputs = {};
    this.currentView = {};
    this.mapDropdown = '';
    this.autoMoorCalculating = false;
    this.allStores.vesselScenarioStore.closeByScenarios = [];
    this.pendingAutomoorRequests = 0;
    this.mooringArrangementModified = false;
    this.tideDataModified = false;
    this.bowMarkerModified = false;
    this.bridgeMarkerModified = false;
    this.sternMarkerModified = false;
    this.vesselProximityError = false;
    this.stagedCranesModified = 0;

    if (this.visibleScenarios) {
      this.visibleScenarios.splice(0);
    }
  };

  setupSession = (user?, accessToken?, refreshToken?, customerCode?, intl?) => {
    // If no user (logging out) just setup a basic default scenario under MA
    this.allStores.vesselScenarioStore.setScenarioData(
      this.allStores.vesselScenarioStore.initialScenarioDataState,
      intl,
    );

    this.allStores.portScenarioStore.setScenarioData(this.allStores.portScenarioStore.initialScenarioDataState);

    this.allStores.appStateStore.setSession({
      user,
      token: {
        accessToken,
        refreshToken,
      },
      customerCode,
    });

    // Set or reset based on value
    this.allStores.appStateStore.resetAppState();
    this.allStores.configStore.fetchConfigData(customerCode, (config) => {
      this.currentView.startDate = format(
        addDays(new Date(), config.applicationSettings.startDateOffset ?? -7),
        'yyyy-MM-dd',
      );
      this.currentView.endDate = format(
        addDays(new Date(), config.applicationSettings.endDateOffset ?? 14),
        'yyyy-MM-dd',
      );
    });
    this.allStores.portStore.fetchPortData(customerCode);
  };

  setCurrentView(key: string, value) {
    this.currentView[key] = value;
  }

  logout = (intl: IntlShape) => {
    console.log('handleLogout: ', this.allStores.vesselScenarioStore.initialScenarioDataState);

    // Check user and setup scenario store initial states
    // If no user (logging out) just setup a basic default scenario under MA
    this.allStores.vesselScenarioStore.setScenarioData(
      this.allStores.vesselScenarioStore.initialScenarioDataState,
      intl,
    );

    this.allStores.portScenarioStore.setScenarioData(this.allStores.portScenarioStore.initialScenarioDataState);

    this.setupSession(null);
    this.authService.logout();
    window.history.replaceState('', module.id, '/');
  };

  /**
   * Sets all initial visible scenarios in the system.
   * @param scenarios
   */
  setVisibleScenarios = (scenarios: VisibleScenario[]) => {
    this.visibleScenarios = scenarios;
  };

  /**
   * // Set max forecasting date based on last available end time in timeseries
   * @param berthName
   */
  setMaxForecastDate = (berthName: string) => {
    if (berthName) {
      this.currentMaxForecastDate = null; // null to begin
      const { timeseriesDataSources } = this.allStores.configStore;
      let newDataSource = timeseriesDataSources[0]; // Make a copy
      const berth = this.allStores.portStore.getBerth(berthName);
      const forecastBerth = berth.forecast;
      // TODO: Add ensemble timeseries here??
      const forecastData = [
        forecastBerth.windSpeed,
        forecastBerth.windDirection,
        forecastBerth.currentSpeed,
        forecastBerth.currentDirection,
        forecastBerth.surfaceElevation,
        forecastBerth.waveHeight,
        forecastBerth.waveDirection,
        forecastBerth.wavePeriod,
        forecastBerth.waves,
      ];
      const forecast = forecastData.filter((obj) => obj !== null && obj !== 'DummyLocation');

      newDataSource = {
        ...newDataSource,
        ids: forecast,
      };

      // Fetching the last forecast time across all timeseries
      fetchTimeseriesValues([newDataSource], this.session.token.accessToken, true).subscribe((data) => {
        const minTimeseries =
          data.length > 0
            ? data.reduce((a, b) => {
                return parseISO(a.data[0]) < parseISO(b.data[0]) ? a : b;
              })
            : null;

        if (minTimeseries && minTimeseries.data && minTimeseries.data.length > 0) {
          this.currentMaxForecastDate = parseISO(`${minTimeseries.data[0]}Z`);
        }
      });
    }
  };

  /**
   * Validate all critical values that are contained within the moorging object
   * @param mooringDetails Mooring
   * @return boolean true | false
   * @memberof AppStateStore
   */
  validMooringData = (mooringDetails: Mooring): boolean => {
    // TODO: validate other mooring properties
    if (
      mooringDetails.latitude === null ||
      mooringDetails.longitude === null ||
      mooringDetails.mooredHeading === null
    ) {
      return false;
    }
    return true; // mooring details look good
  };

  /**
   * Validates the provided scenario and updates the status back into the given scenario, or the `customValidtyObject`.
   * @param scenario Provided scenario to validate
   * @param scenarioValidity Custom return object
   * @param diagnostics true/false
   */
  validatePortScenario = (
    scenario: VisibleScenario,
    scenarioValidity: PortScenarioValidity,
    diagnostics: Diagnostics,
  ) => {
    console.log('%c Validating port scenario... ', 'background-color:#ffff00;color:#333;font-weight:bold');

    transaction(() => {
      scenarioValidity.valid = true;
    });
  };

  /**
   * Validates the provided scenario and updates the status back into the given scenario, or the `customValidtyObject`.
   * @param scenario Provided scenario to validate
   * @param scenarioValidity Custom return object
   * @param diagnostics true/false
   */
  validateVesselScenario = (
    scenario: VisibleScenario,
    scenarioValidity: VesselScenarioValidity,
    diagnostics: Diagnostics,
    intl: IntlShape,
    proximityOnly = false,
  ) => {
    console.log(
      `%c Validating ${scenario.data.name}${proximityOnly ? ' (proximity only)' : ''}... `,
      'background-color:#ffff00;color:#333;font-weight:bold',
    );

    let bollards: AllBollard[] = [];
    bollards = this.allStores.portStore.allBollards;

    scenarioValidity.mooring = cloneDeep(this.allStores.vesselScenarioStore.initialValidityState.mooring);

    const errors: SnackbarErrorSet[] = [];

    if (scenario.data.mooring.berthName) {
      // Validate proximity
      const proximityResult = this.validateVesselProximity(scenario, diagnostics);      

      if (proximityResult.valid) {
        console.log(`%c ${scenario.data.name} Proximity OK. `, 'background-color:#76FF03;color:#333;', proximityResult);
      } else if (this.showWarnings) {
        console.log(
          `%c ${scenario.data.name} proximity too close to other vessels across similar duration.`,
          'color:yellow;background-color:red;',
          proximityResult,
        );

        errors.push({
          id: 'lines',
          messageId: 'components.ma.forms.mooringProximityError',
          items: proximityResult.summary
            .filter((s) => !s.valid)
            .map((x) => {
              return {
                id: x.scenarioName,
                title: x.scenarioName,
              } as SnackbarItem;
            }),
        });
      }

      // Validate lines if proximity has passed and automoor is not still calculating
      if (!proximityOnly && !this.autoMoorCalculating) {
        const linesResult = this.validateMooringLines(scenario, bollards, diagnostics, intl);

        if (linesResult.valid) {
          console.log(`%c Lines OK. `, 'background-color:#76FF03;color:#333;');
        } else if (this.showWarnings) {
          console.log(
            `%c One or more problems with the mooring system.`,
            'color:yellow;background-color:red;',
            linesResult,
          );

          let issues: SnackbarItem[] = [];

          linesResult.lines.forEach((line) => {
            line.lineIssues.forEach((issue) => {
              issues = [
                ...issues,
                {
                  id: line.lineName + issue.lineValidityType,
                  title: line.lineName,
                  messageId: issue.messageId,
                  parts: issue.parts,
                },
              ];
            });
          });

          errors.push({
            id: 'lines',
            messageId: 'components.ma.forms.mooringLinesInvalid',
            items: issues,
          });
        }

        scenarioValidity.mooring.lines = linesResult;
      }

      if (errors && errors.length > 0) {
        if (proximityOnly) {
          const error = errors.find((e) => e.id === 'proximity');

          if (error) this.showSnackbar(error.messageId, error.items);
        } else {
          this.showErrorSet(errors);
        }
      }

      scenarioValidity.mooring.proximity = proximityResult;

      scenarioValidity.mooring.valid = (scenarioValidity.mooring.proximity.valid || (scenarioValidity.mooring.proximity.minDistance !== undefined && scenarioValidity.mooring.proximity.minDistance > 1)) && scenarioValidity.mooring.lines.valid;
    }

    scenarioValidity.valid = scenarioValidity.mooring.valid;
  };

  validateVesselProximity = (scenario: VisibleScenario, diagnostics: Diagnostics): ProximityValidation => {
    const berth: Berth = this.allStores.portStore.getBerth(scenario.data.mooring.berthName);
    const allBerths = this.allStores.portStore.getAllBerths(); // All berths with data in a flat list.
    const adjacentBerths = this.allStores.portStore.getAdjacentBerths(berth.berthName, allBerths);

    let closeByScenarios: CrossAwarenessScenario[] = null;
    const results: ProximityValidation = cloneDeep(
      this.allStores.vesselScenarioStore.initialValidityState.mooring.proximity,
    );

    // Start valid
    results.valid = true;
    results.minDistance = undefined;    
    this.vesselProximityError = false;

    // Find all scenarios which intersect incoming scenario's start/end time
    if (scenario.data.usesCrossScenarioAwareness && scenario.data.mooring.startTime && scenario.data.mooring.endTime) {
      const startTime = new Date(scenario.data.mooring.startTime).getTime();
      const endTime = new Date(scenario.data.mooring.endTime).getTime();
      // Only get adjacent scenarios to the CURRENT scenario, then use this to filter closeByScenarios which has all the enhanced data.
      const adjacentScenarios = this.allStores.portStore.getAdjacentScenarios(scenario, diagnostics);
      const closeScenarios =
        adjacentScenarios != null
          ? this.allStores.vesselScenarioStore.closeByScenarios
              .filter((x) => adjacentScenarios.some((as) => as.id === x.id))
              .map((s) => {
                const adjacentScenario = adjacentScenarios.find((as) => as.id === s.id);

                // Bind close scenario enhanced data, with adjacent scenario distance and toTheRight calcs.
                return {
                  ...s,
                  ...adjacentScenario,
                };
              })
          : [];

      closeByScenarios = closeScenarios.filter((x) => {        
        // Only consider scenarios with mooring data and that doesnt match selected id:
        if (x.id === scenario.id || !x.data.mooring) {
          return false;
        }

        // Only check scenarios that are within the same berth or adjacent berths:
        if (!adjacentBerths.includes(x.data.mooring.berthName)) {
          return false;
        }

        // Check that time of scenarios are overlapping:
        const iteratedStartTime = new Date(x.data.mooring.startTime).getTime();
        const iteratedEndTime = new Date(x.data.mooring.endTime).getTime();

        return (
          (iteratedStartTime >= startTime && iteratedStartTime < endTime) ||
          (iteratedEndTime >= startTime && iteratedEndTime < endTime) ||
          (startTime >= iteratedStartTime && startTime < iteratedEndTime) ||
          (endTime >= iteratedStartTime && endTime < iteratedEndTime)
        );
      });

      // Only bring in where necessary. Otherwise can overwhelm console
      // if (diagnostics?.validationEngine) {
      //   console.log(
      //     `${allScenarios.length} scenarios found between ${scenario.data.mooring.startTime} – ${
      //       scenario.data.mooring.endTime
      //     } %c(${allScenarios.map((x) => x.data.name).join(',')})`,
      //     // 'color:#888',
      //   );
      // }
      const threshold = berth.minimumVesselDistance.find(
        (b) => scenario.data.vessel.loa >= b.from && scenario.data.vessel.loa < b.to,
      ).distance;

      closeByScenarios.forEach((iterateScenario: CrossAwarenessScenario) => {
        const item: ProximityValidationItem = {
          valid: true,
          scenarioId: iterateScenario.id,
          scenarioName: iterateScenario.data.name,
          distance: iterateScenario.distance,
        };

        if (results.minDistance === undefined || iterateScenario.distance < results.minDistance) {
          results.minDistance = iterateScenario.distance;
        }

        if (iterateScenario.distance < 1) {
          item.valid = false;
          results.valid = false;
          this.vesselProximityError = true;
        } else if (iterateScenario.distance < threshold) {
          item.valid = false;
          results.valid = false;
        }

        const exist = results.summary.some((scenario) => scenario.scenarioId === item.scenarioId);

        if (!exist) results.summary.push(item);
      });

      // console.log(scenario.data.name, JSON.stringify(results.summary, null, 2));
    }

    return results;
  };

  validateMooringLines = (
    scenario: VisibleScenario,
    bollards: AllBollard[],
    diagnostics: Diagnostics,
    intl: IntlShape,
  ): LinesValidation => {
    const lineResult: LinesValidation = this.allStores.vesselScenarioStore.initialValidityState.mooring.lines;
    const lines: LineValidity[] = [];
    const mooringArrangement: Line[] =
      scenario.data.mooring.mooringArrangements[scenario.data.mooring.mooringArrangementName];
    // console.log('validateMooringLines', JSON.stringify(mooringArrangement));
    const lineNames = getLineNames(mooringArrangement, null, null, diagnosticsLineNames).map(
      (ln) => `${ln.name} #${ln.number}`,
    );
    const translatedLineNames = getLineNames(mooringArrangement, intl).map((ln) => `${ln.name} #${ln.number}`);
    const fairleads = mooringArrangement.map((row) => row.fairleadId);

    const { validation } = this.allStores.configStore.appConfig.modules
      .find((m) => m.id === 'MooringAnalysis')
      .pages.find((p) => p.id === 'MooringAnalysis').config;

    // TODO: Uncomment for diagnostic purposes
    // if (customValidityObject) {
    //   console.log('ORIGINAL LINES');
    //   mooringArrangement.forEach(line => {
    //     let valid = true;
    //     const lineName = lineNames[line.id];
    //     const lineBollard = bollards[line.bollardIndex];
    //     const availableLines = this.allStores.portStore.getAvailableLines(lineBollard as AllBollard, scenario, diagnostics);
    //     // Once invalid, stays invalid
    //     if (availableLines < 0) {
    //       valid = false;
    //     }
    //     console.log(
    //       `${lineName}<>${lineBollard.name} - ${valid} (max: ${lineBollard.associatedProfile.maxLines}, used: ${lineBollard.associatedProfile.maxLines - availableLines})`,
    //     );
    //   });
    //   console.log('EDITING SCENARIO');
    // }
    mooringArrangement.forEach((line, index) => {
      let lineIssues: LineIssue[] = [];
      const lineName = translatedLineNames[index + 1];

      if (line.bollardIndex === null) {
        lineIssues = [
          ...lineIssues,
          {
            lineValidityType: LineValidityType.NoBollardsAvailable,
            parts: [lineName],
            messageId: 'components.ma.forms.mooringLinesNoBollardsAvailable',
          },
        ];
      } else {
        if (line.bollardIndex >= 0) {
          let values = this.bollardCapacityExceeded(bollards, scenario, line, diagnostics);

          if (values) {
            lineIssues = [
              ...lineIssues,
              {
                lineValidityType: LineValidityType.BollardCapacityExceeded,
                parts: values,
                messageId: 'components.ma.forms.mooringLinesBollardCapacityExceeded',
              },
            ];
          }

          values = this.lineCharacteristicsViolated(bollards, scenario, line, LineValidityType.HorizontalAngleViolated);

          if (values) {
            lineIssues = [
              ...lineIssues,
              {
                lineValidityType: LineValidityType.HorizontalAngleViolated,
                parts: values,
                messageId: 'components.ma.forms.mooringLinesHorizontalAngleViolated',
              },
            ];
          }

          values = this.lineCharacteristicsViolated(bollards, scenario, line, LineValidityType.VerticalAngleViolated);

          if (values) {
            lineIssues = [
              ...lineIssues,
              {
                lineValidityType: LineValidityType.VerticalAngleViolated,
                parts: values,
                messageId: 'components.ma.forms.mooringLinesVerticalAngleViolated',
              },
            ];
          }

          values = this.lineCharacteristicsViolated(bollards, scenario, line, LineValidityType.TooLong);

          if (values) {
            lineIssues = [
              ...lineIssues,
              {
                lineValidityType: LineValidityType.TooLong,
                parts: values,
                messageId: 'components.ma.forms.mooringLinesTooLong',
              },
            ];
          }

          // Line with Constant Tension Winch going to a bollard with a capacity of less than limit:
          if (line.shoreTension?.enabled) {
            values = this.constantTensionWinchCapacityExceeded(this.allStores.portStore.portData, line, bollards);

            if (values) {
              lineIssues = [
                ...lineIssues,
                {
                  lineValidityType: LineValidityType.ConstantTensionWinchBlockCapacity,
                  parts: values,
                  messageId: 'components.ma.forms.constantTensionWinchBlockBollardLowCapacity',
                },
              ];
            }
          }
          values = this.lineCharacteristicsViolated(bollards, scenario, line, LineValidityType.TooShort);

          if (values) {
            lineIssues = [
              ...lineIssues,
              {
                lineValidityType: LineValidityType.TooShort,
                parts: values,
                messageId: 'components.ma.forms.mooringLinesTooShort',
              },
            ];
          }

          if (line.fairleadId) {
            values = this.fairleadCapacityExceeded(fairleads, line);

            if (values) {
              lineIssues = [
                ...lineIssues,
                {
                  lineValidityType: LineValidityType.FairleadCapacityExceeded,
                  parts: values,
                  messageId: 'components.ma.forms.mooringLinesFairleadCapacityExceeded',
                },
              ];
            }
          }

          // Disable validation action if excluded
          // To disable this at a port-specific level, utilise this override in port-specific config
          /* 
            {
            "id": "MooringAnalysis",
            "path": "$.pages[?(@.id == 'MooringAnalysis')].config.validation",
            "overrides": [
              {
                "excludes": [
                  "mooringLinesCrossingDeck"
                ]
              }
            ]
          },
          */
          if (
            !validation ||
            isEmpty(validation.excludes) ||
            !validation.excludes.includes('mooringLinesCrossingDeck')
          ) {
            const fairlead: Fairlead = scenario.data.vessel.fairleads.find(
              (fairlead) => fairlead.id === line.fairleadId,
            );
            const fairleadPosition = [fairlead.x, fairlead.y];

            const { deckPlane, beam } = scenario.data.vessel;
            const fullDeckPlane = getFullDeckPlane(deckPlane, beam);

            if (
              this.vesselSilhoutteIntersects(scenario, bollards, fairleadPosition, line.bollardIndex, fullDeckPlane)
            ) {
              lineIssues = [
                ...lineIssues,
                {
                  lineValidityType: LineValidityType.CrossingDeck,
                  messageId: 'components.ma.forms.mooringLinesCrossingDeck',
                },
              ];
            }
          }

          mooringArrangement.forEach((secondLine: Line) => {
            if (secondLine.bollardIndex !== null) {
              if (
                this.lineVerticalThresholdViolated(
                  scenario,
                  bollards,
                  line.bollardIndex,
                  line.fairleadId,
                  secondLine.bollardIndex,
                  secondLine.fairleadId,
                )
              ) {
                lineIssues = [
                  ...lineIssues,
                  {
                    lineValidityType: LineValidityType.VerticallyTooClose,
                    parts: [lineNames[secondLine.id]],
                    messageId: 'components.ma.forms.mooringLinesVerticallyTooClose',
                  },
                ];
              }
            }
          });
        }
      }

      lines.push({
        lineId: line.id,
        lineName,
        lineIssues,
      });
    });

    lineResult.lines = lines;
    lineResult.valid = !lines.some((l) => l.lineIssues.length > 0);
    lineResult.name = scenario.data.name;

    return lineResult;
  };

  getScenario = (scenarioId: string) => {
    const scenario = this.visibleScenarios.find((vs) => vs.id === scenarioId);

    return scenario;
  };

  showSnackbar = (messageId: string, items?: SnackbarItem[], severity?: Color | 'toast', hideDuration = 4000) => {
    this.setSnackbarVisibility({
      open: true,
      errorSet: [
        {
          id: 'generic',
          messageId,
          items,
        },
      ],
      severity: severity ?? 'warning',
      hideDuration,
    });
  };

  showErrorSet = (errorSet: SnackbarErrorSet[], severity?: Color | 'toast', hideDuration = 3600 * 1000) => {
    this.setSnackbarVisibility({
      open: true,
      errorSet,
      severity: severity ?? 'warning',
      hideDuration,
    });
  };

  showErrors = (diagnosticsConfig: Diagnostics, intl: IntlShape) => {
    this.allStores.vesselScenarioStore.validateCloseByScenarios(
      this.allStores.vesselScenarioStore.closeByScenarios,
      diagnosticsConfig,
      intl,
    );
  };

  setSnackbarVisibility = ({ open, errorSet, severity, hideDuration = 5000 }: SnackbarVisibilityParameters) => {
    this.snackbarParams = {
      open,
      errorSet,
      severity,
      hideDuration,
    };
  };

  setAlertDialogVisibility = ({ open, title, message, details, maxWidth, scroll }: AlertDialogVisibilityParameters) => {
    this.alertDialogParams = {
      open,
      title,
      message,
      details,
      maxWidth,
      scroll,
    };
  };

  showAlertDialog = (
    title,
    message: string | React.ReactElement,
    details?: any,
    maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = false,
    scroll = false,
  ) => {
    this.setAlertDialogVisibility({
      open: true,
      title: title,
      message,
      details,
      maxWidth,
      scroll,
    });
  };

  /**
   * Determines if a line interesects with the hull based on the indexes of fairlead and bollard
   * @param scenario The scenario
   * @param bollards A list of all bollards
   * @param fairleadPosition The fairlead position of the line
   * @param bollardIndex The bollard index
   */
  vesselSilhoutteIntersects = (
    scenario: VisibleScenario,
    bollards: Partial<AllBollard>[],
    fairleadPosition: number[],
    bollardIndex: number,
    fullDeckPlane: Point2D[],
  ): boolean => {
    const bollard = bollards[bollardIndex];
    const berth: Berth = this.allStores.portStore.getBerth(scenario.data.mooring.berthName);
    const headingAngle = getHeadingAngle(scenario.data.mooring, berth);
    const vesselCentre = new UtmLatLon(scenario.data.mooring.latitude, scenario.data.mooring.longitude);
    const bollardCoordinates = latLngToUtm(
      [
        {
          x: bollard.point[0],
          y: bollard.point[1],
        },
      ],
      vesselCentre,
      headingAngle,
    );

    return this.vesselSilhoutteIntersectsByObject(
      fairleadPosition,
      {
        ...bollard,
        point: bollardCoordinates[0],
      },
      fullDeckPlane,
    );
  };

  /**
   * Determines if a line interesects with the hull based on the fairlead and bollard
   * @param vessel The vessel from the scenario
   * @param fairlead The fairlead
   * @param bollard The bollard
   */
  vesselSilhoutteIntersectsByObject = (
    fairleadPosition: number[],
    bollard: Partial<AllBollard>,
    deckPlaneVessel: Point2D[],
  ): boolean => {
    // Reduce deckplane slightly for intersect:
    const fullDeckplane: Point2D[] = [...deckPlaneVessel.map((d) => ({ x: d.x * 0.999, y: d.y * 0.999 } as Point2D))];

    const [x1, y1] = fairleadPosition;
    const [x2, y2] = bollard.point;

    for (let i = 0; i < fullDeckplane.length - 1; i++) {
      const x3 = fullDeckplane[i].x;
      const x4 = fullDeckplane[i + 1].x;
      const y3 = fullDeckplane[i].y;
      const y4 = fullDeckplane[i + 1].y;
      const det = Math.round(((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)) * 1000);

      if (det !== 0) {
        const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
        const px = x1 + t * (x2 - x1);
        const py = y1 + t * (y2 - y1);

        // Add a small offset (0.1m) on the deck plane to avoid false negatives on coarse deck plans
        if (
          Math.min(x1, x2) < px &&
          px < Math.max(x1, x2) &&
          Math.min(y1, y2) < py &&
          py < Math.max(y1, y2) &&
          Math.min(x3, x4) <= px &&
          px <= Math.max(x3, x4) &&
          Math.min(y3, y4) <= py + 0.1 &&
          py <= Math.max(y3, y4) + 0.1
        ) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Determines if a line is vertically too close to another line based on the indexes and ids of the bollards and fairleads
   * @param scenario The scenario
   * @param bollards All bollards
   * @param firstBollardIndex The index of the first bollard
   * @param firstFairleadId The id of the first fairlead
   * @param secondBollardIndex The index of the second bollard
   * @param secondFairleadId The id of the second fairlead
   */
  lineVerticalThresholdViolated = (
    scenario: VisibleScenario,
    bollards: AllBollard[],
    firstBollardIndex: number,
    firstFairleadId: number,
    secondBollardIndex: number,
    secondFairleadId: number,
  ): boolean => {
    const firstBollard = bollards[firstBollardIndex];
    const secondBollard = bollards[secondBollardIndex];
    const berth: Berth = this.allStores.portStore.getBerth(scenario.data.mooring.berthName);
    const headingAngle = getHeadingAngle(scenario.data.mooring, berth);
    const vesselCentre: UtmLatLon = new UtmLatLon(scenario.data.mooring.latitude, scenario.data.mooring.longitude);

    if (!firstBollard || !secondBollard) return false;

    // Calculate bollard coordinates in local coordinates:
    const bollardCoordinates = latLngHeightToUtm(
      [
        {
          x: firstBollard.point[0],
          y: firstBollard.point[1],
          z: firstBollard.point[2],
        },
        {
          x: secondBollard.point[0],
          y: secondBollard.point[1],
          z: secondBollard.point[2],
        },
      ],
      vesselCentre,
      headingAngle,
    );

    const firstFairlead: Fairlead = scenario.data.vessel.fairleads.find((fairlead) => fairlead.id === firstFairleadId);
    const secondFairlead: Fairlead = scenario.data.vessel.fairleads.find(
      (fairlead) => fairlead.id === secondFairleadId,
    );
    const vessel = scenario.data.vessel;
    const minDraft = vessel.draftType === 1 ? Math.min(vessel.draftForeValue, vessel.draftMidValue, vessel.draftAftValue) : vessel.draftMin;
    
    var tideData = scenario.data.environmentalConditions.tide;
    
    const maxTide = tideData.type === 1 ? tideData.value : tideData.max;

    return this.lineVerticalThresholdViolatedByObject(
      {
        ...firstBollard,
        point: bollardCoordinates[0],
      },
      firstFairlead,
      {
        ...secondBollard,
        point: bollardCoordinates[1],
      },
      secondFairlead,
      minDraft,
      maxTide,
    );
  };

  /**
   * A modified version of round that rounds negative values down
   * @param value The value
   * @param decimals The number of decimals
   */
  realRound = (value: number, decimals: number): number => {
    return Math.sign(value) * round(Math.abs(value), decimals);
  };

  /**
   * Determines if a line is vertically too close to another line based on the indexes and ids of the bollards and fairleads
   * @param firstBollard The first bollard
   * @param firstFairlead The first fairlead
   * @param secondBollard The second bollard
   * @param secondFairlead The second fairlead
   */
  lineVerticalThresholdViolatedByObject = (
    firstBollard: Partial<AllBollard>,
    firstFairlead: Fairlead,
    secondBollard: Partial<AllBollard>,
    secondFairlead: Fairlead,
    minDraft: number,
    maxTide: number,
  ): boolean => {
    const minimumVerticalDistance = 1.0;
    const x1 = firstFairlead.x;    
    const y1 = firstFairlead.y;
    const [x2, y2, z2var] = firstBollard.point;
    
    const x3 = secondFairlead.x;
    const y3 = secondFairlead.y;
    const [x4, y4, z4var] = secondBollard.point;

    // Calculate elevations relative to the vessel center - Taking max tide and min draft into account:
    const z1 = firstFairlead.z - minDraft;
    const z2 = z2var - maxTide;
    const z3 = secondFairlead.z - minDraft;
    const z4 = z4var - maxTide;

    // Calculate determinant of lines in x-y plane:
    const det = Math.round(((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)) * 1000);

    // If lines are not parallel:
    if (det !== 0) {
      // Calculate intersection point of lines:
      const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
      let px = x1 + t * (x2 - x1);
      let py = y1 + t * (y2 - y1);

      px = this.realRound(px, 1);
      py = this.realRound(py, 1);

      // If lines intersect (without start/end points):
      if (
        Math.min(this.realRound(x1, 1), this.realRound(x2, 1)) < px &&
        px < Math.max(this.realRound(x1, 1), this.realRound(x2, 1)) &&
        Math.min(this.realRound(y1, 1), this.realRound(y2, 1)) < py &&
        py < Math.max(this.realRound(y1, 1), this.realRound(y2, 1)) &&
        Math.min(this.realRound(x3, 1), this.realRound(x4, 1)) < px &&
        px < Math.max(this.realRound(x3, 1), this.realRound(x4, 1)) &&
        Math.min(this.realRound(y3, 1), this.realRound(y4, 1)) < py &&
        py < Math.max(this.realRound(y3, 1), this.realRound(y4, 1))
      ) {
        // Length of lines:
        const length12 = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + (z2 - z1) ** 2);
        const length34 = Math.sqrt((x4 - x3) ** 2 + (y4 - y3) ** 2 + (z4 - z3) ** 2);

        // Calculate the slope of line 1
        const v12 = (px - x1) / ((x2 - x1) / length12);
        // Then z value of intersection point of line 1
        const zt12 = v12 * ((z2 - z1) / length12) + z1;
        // Calculate the slope of line 2
        const v34 = (px - x3) / ((x4 - x3) / length34);
        // Then z value of intersection point of line 2
        const zt34 = v34 * ((z4 - z3) / length34) + z3;
        // Then distance between the two intersection points
        const distance = Math.abs(zt34 - zt12);

        // Increment the number of lines crossing in this tally item
        if (distance < minimumVerticalDistance) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Determines if a certain characteristics of a line is violating thresholds and returns a set of strings for use to indicate the violation
   * @param bollards All bollards
   * @param scenario The scenario
   * @param line The line to analyze
   * @param lineValidityType The check to perform
   */
  lineCharacteristicsViolated = (
    bollards: Partial<AllBollard>[],
    scenario: VisibleScenario,
    line: Line,
    lineValidityType: LineValidityType,
  ): string[] => {
    const bollard = bollards[line.bollardIndex];
    const fairlead = scenario.data.vessel.fairleads.find((fairlead) => fairlead.id === line.fairleadId);

    /*
     * // TODO: properly validate mooring data at a higher level)
     *
     * the if() stmt is a quick fix to protect the app from crashing
     * for garbage lat/long values.
     */
    if (scenario.data.mooring.latitude === null || scenario.data.mooring.longitude === null || !line.bollardIndex)
      return null;

    const characteristics = this.allStores.vesselScenarioStore.getLineCharacteristics(
      scenario,
      fairlead,
      bollard,
      line,
      false,
    );

    (line as any).characteristics = characteristics;

    switch (lineValidityType) {
      case LineValidityType.HorizontalAngleViolated: {
        // Inside trumpet

        // Strip decimal from horizontalLineAngleMin as sometimes this may be 30.1 degree for example and we don't want the UI to throw an error.
        // These small discrepencies are ok confirmed by ALHA.
        const violated = !(
          Math.trunc(characteristics.horizontalLineAngle) >= Math.trunc(characteristics.horizontalLineAngleMin) &&
          Math.trunc(characteristics.horizontalLineAngle) <= Math.trunc(characteristics.horizontalLineAngleMax)
        )
          ? [
              characteristics.horizontalLineAngle.toFixed(1),
              characteristics.horizontalLineAngleMin.toFixed(0),
              characteristics.horizontalLineAngleMax.toFixed(0),
            ] // how it violated
          : null; // not violated

        return violated;
      }

      case LineValidityType.VerticalAngleViolated: // Not too steep
        return !(Math.trunc(characteristics.verticalLineAngle) <= Math.trunc(line.lineAngleVerticalMax))
          ? [characteristics.verticalLineAngle.toFixed(1), line.lineAngleVerticalMax.toFixed(0)]
          : null;
      case LineValidityType.TooShort: // Not too short
        return !(line.lineLengthMin <= characteristics.totalLineLength)
          ? [
              valueToDisplay(
                characteristics.totalLineLength,
                'm',
                this.allStores.configStore.appConfig.applicationSettings.unitSystemIsSi,
                true,
                MA_ROUND_DECIMALS,
              ),
              valueToDisplay(
                line.lineLengthMin,
                'm',
                this.allStores.configStore.appConfig.applicationSettings.unitSystemIsSi,
                true,
                MA_ROUND_DECIMALS,
              ),
            ]
          : null;
      case LineValidityType.TooLong: // Not too long
        return !(Math.trunc(characteristics.totalLineLength) <= Math.trunc(line.lineLengthMax))
          ? [
              valueToDisplay(
                characteristics.totalLineLength,
                'm',
                this.allStores.configStore.appConfig.applicationSettings.unitSystemIsSi,
                true,
                MA_ROUND_DECIMALS,
              ),
              valueToDisplay(
                line.lineLengthMax,
                'm',
                this.allStores.configStore.appConfig.applicationSettings.unitSystemIsSi,
                true,
                MA_ROUND_DECIMALS,
              ),
            ]
          : null;
    }

    return null;
  };

  /**
   * Checks of the number of lines to a specific bollard exceeds the capacity
   * @param bollards All bollards
   * @param scenario The scenario
   * @param line The line to check
   * @param diagnostics Indicates if diagnostics should be emitted
   */
  bollardCapacityExceeded = (
    bollards: Partial<AllBollard>[],
    scenario: VisibleScenario,
    line: Line,
    diagnostics: Diagnostics,
  ): string[] | null => {
    const lineBollard = bollards[line.bollardIndex];
    const availableLines = this.allStores.portStore.getAvailableLines(
      lineBollard as AllBollard,
      scenario,
      false,
      diagnostics,
    );

    const exceeded = availableLines < 0;

    return exceeded ? [lineBollard.associatedProfile.maxLines.toString()] : null;
  };

  /**
   * Check for more the one line per fairlead.
   * @param fairleads All fairleads selected on the current vessel scenario
   * @param line the line to check
   */
  fairleadCapacityExceeded = (fairleads: number[], line: Line) => {
    const duplicatedFairleadIds = _.filter(fairleads, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
    const exceedCapacity = duplicatedFairleadIds.some((id) => id === line.fairleadId);

    return exceedCapacity ? [LineValidityType.FairleadCapacityExceeded.toString()] : null;
  };

  /**
   * Check for more the one line per fairlead.
   * @param portData Port data
   * @param line the line to check
   */
  constantTensionWinchCapacityExceeded = (portData: PortData, line: Line, bollards: Partial<AllBollard>[]) => {
    const actualBollard = bollards[line.bollardIndex];

    let exceeded = false;
    const constantTensionWinchProfile = portData.constantTensionWinchProfiles[0];

    if (actualBollard.profileId && constantTensionWinchProfile.minimumRequiredBollardStrength) {
      const bollardProfiles = portData.bollardProfiles;
      const bollardProfile = bollardProfiles.find(
        (bollardProfile) => bollardProfile.bollardProfileId === actualBollard.profileId,
      );

      exceeded = bollardProfile.horizontalBreakingStrength < constantTensionWinchProfile.minimumRequiredBollardStrength;

      return exceeded
        ? [actualBollard.name, constantTensionWinchProfile.minimumRequiredBollardStrength.toString()]
        : null;
    }

    return null;
  };

  resetMooringArrangement = () => {
    delete this.allStores.vesselScenarioStore.scenarioData.data.mooring.mooringArrangements.Customised;
    let sortedArrangements = null;

    if (!this.allStores.vesselScenarioStore.scenarioData.data.mooring.defaultMooringArrangementName) {
      sortedArrangements = Object.keys(
        this.allStores.vesselScenarioStore.scenarioData.data.mooring.mooringArrangements,
      ).sort((a, b) => a.localeCompare(b));
    }

    this.allStores.vesselScenarioStore.scenarioData.data.mooring.mooringArrangementName =
      this.allStores.vesselScenarioStore.scenarioData.data.mooring.defaultMooringArrangementName ??
      sortedArrangements[sortedArrangements.length - 1];

    this.mooringArrangementModified = false;

    Object.keys(this.allStores.vesselScenarioStore.scenarioData.data.mooring.mooringArrangements).forEach((key) => {
      this.allStores.vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[key].forEach((line) => {
        line.bollardIndex = null;
      });
    });
  };
}
