import { fetchScenario, fetchUrl } from '@dhi/react-components';
import { Diagnostics } from '../interfaces/Config';
import { AutomoorResponse, EnvironmentalConditions, Mooring, Port, Vessel } from '../interfaces/VesselScenario';
import { AppStateStore } from '../stores';

/**
 * Fetch scenario by scenarioId.
 * @param appStateStore
 * @param connection
 * @param successCallback
 */
export const quickFetchScenario = (
  appStateStore: AppStateStore,
  connection: string,
  id: string,
  successCallback: (scenario: Record<any, any>) => void,
) => {
  const scenarioConfigDataSource = {
    host: process.env.REACT_APP_API_ENDPOINT_URL,
    connection: connection,
  };

  fetchScenario(scenarioConfigDataSource, appStateStore.session.token.accessToken, id).subscribe(
    (res) => {
      res.data = res.data ? JSON.parse(res.data) : res.data;

      if (successCallback) {
        successCallback(res.data);
      }
    },
    (error) => {
      console.log(error);
    },
  );
};

/**
 * Fetch autoMooring result based on mooring.
 * @param appStateStore
 * @param connection
 * @param successCallback
 */
export const calculateAutoMooring = (
  appStateStore: AppStateStore,
  scenarioId: string,
  autoMoorRequest: {
    mooringSetup: { port: Port; vessel: Vessel; mooring: Mooring; environmentalConditions: EnvironmentalConditions };
    nearestVesselDistances: Record<string, string>[];
  },
  diagnostics: Diagnostics,
  successCallback: (results: AutomoorResponse) => void,
  errorCallback: () => void,
) => {
  const payload = JSON.stringify({
    eventId: scenarioId,
    ...autoMoorRequest,
  });

  if (diagnostics.autoMooring) {
    console.time('AutomoorProfiling_RequestOnly');
  }

  appStateStore.pendingAutomoorRequests++;

  fetchUrl(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/AutoMooring`, {
    method: 'POST',
    body: payload,
    additionalHeaders: {
      Authorization: `Bearer ${appStateStore.session.token.accessToken}`,
    },
  }).subscribe(
    (result) => {
      appStateStore.pendingAutomoorRequests--;

      if (diagnostics.autoMooring) {
        console.timeEnd('AutomoorProfiling_RequestOnly');
      }

      if (successCallback) {
        successCallback(result);
      }
    },
    (error) => {
      appStateStore.pendingAutomoorRequests--;
      if (appStateStore.pendingAutomoorRequests === 0) {
        appStateStore.autoMoorCalculating = false;
      }
      errorCallback();
      console.log(error);
    },
  );
};
