import { VesselScenarioContainer } from '../interfaces/VesselScenario';
import { AppStateStore, VesselScenarioStore } from '../stores';

const scenarioIsAutoGeneratedAndDynamic = (vesselScenario: VesselScenarioContainer): boolean => {
  if (!vesselScenario.data.autoGeneratedEvent) return false;

  // If Scenario has already been repaired before, return false
  if (vesselScenario.data.port?.bollards !== undefined && vesselScenario.data.port?.bollards.length > 0) return false;

  // Otherwise check if auto generated scenario has all required data to be considered dynamic and should use adjacent vessels
  if (vesselScenario.data.autoGeneratedEvent &&
    vesselScenario.data.mooring.berthName &&
    vesselScenario.data.mooring.berthName !== '' &&
    vesselScenario.data.mooring.startTime &&
    vesselScenario.data.mooring.endTime && 
    vesselScenario.data.mooring.berthMarker &&
    vesselScenario.data.mooring.berthMarker !== -999) {
      return true;
    }

    return false;
}

/**
 * Loop through all visible scenarios and check if date/time intersects.
 * @param scenario
 * @param appStateStore
 * @returns array with scenarios
 */
const getScenariosWithDateOverlaping = (
  scenario: VesselScenarioContainer,
  appStateStore: AppStateStore,
  vesselScenarioStore: VesselScenarioStore,
): VesselScenarioContainer[] => {
  // Only run this logic if there are any visibile scenarios to run it on
  if (appStateStore.visibleScenarios && appStateStore.visibleScenarios.length > 1) {
    const startTime = new Date(scenario.data.mooring.startTime).getTime();
    const endTime = new Date(scenario.data.mooring.endTime).getTime();

    try {
      // If the current vesselScenarioStore is found in visible scenarios,
      // filter it, and use the most up-to-date copy from vesselScenarioStore.
      const overlap = appStateStore.visibleScenarios
        .filter((f) => f.id !== vesselScenarioStore.scenarioData.id)
        .filter((x) => {
          // Only include scenarios that use cross scenario awareness or are auto generated and dynamic:
          if (x.data.mooring && (x.data.usesCrossScenarioAwareness || scenarioIsAutoGeneratedAndDynamic(x))) {
            const iteratedStartTime = new Date(x.data.mooring.startTime).getTime();
            const iteratedEndTime = new Date(x.data.mooring.endTime).getTime();

            // https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
            // beautiful
            return startTime < iteratedEndTime && iteratedStartTime < endTime;
          }

          return false;
        })
        .concat(vesselScenarioStore.scenarioData);

      return overlap;
    } catch (err) {
      console.log('There is no visible scenarios: ', err);

      return [];
    }
  } else {
    return [];
  }
};

export { getScenariosWithDateOverlaping, scenarioIsAutoGeneratedAndDynamic };

